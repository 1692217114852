import type { RouteRecordRaw } from "vue-router";

const onError = (err: any) => {
  console.error("Failed to load async component: ", err);
  throw new Error(err);
};

/*
  key -> 对应的是 父级路由名 / 子级路由名
*/
export const constantRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "/",
    redirect: "/pages/data-panel"
  },
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login/index.vue").catch(onError)
  },
  {
    path: "/cas-callback",
    name: "cas登录",
    component: () => import("@/views/login/cas-callback.vue").catch(onError)
  }
  /* {
    path: "/:pathMatch(.*)",
    name: "不存在页面",
    meta: {
      key: "404"
    },
    component: () => import("@/layout/index.vue").catch(onError),
    redirect: "/:pathMatch(.*)/not-found",
    children: [
      {
        name: "404",
        path: "/:pathMatch(.*)/not-found",
        component: () => import("@/views/NotFound/index.vue").catch(onError)
      }
    ] 
  } */
];

export const routes: RouteRecordRaw[] = [
  {
    name: "首页",
    path: "/pages",
    component: () => import("@/layout/index.vue").catch(onError),
    meta: {
      key: "/pages"
    },
    // redirect: "/pages/data-panel",
    children: [
      {
        name: "数据大盘",
        path: "/pages/data-panel",
        children: [
          {
            name: "数据大盘",
            path: "/pages/data-panel",
            component: () => import("@/views/DataPanel/index.vue").catch(onError),
            meta: {
              keepAlive: true
            }
          },
          {
            name: "DAU大盘",
            path: "/pages/data-dau",
            component: () => import("@/views/DauPanel/index.vue").catch(onError),
            meta: {
              keepAlive: true
            }
          }
        ]
      },
      {
        name: "广告端管理",
        path: "/pages/ad",
        children: [
          {
            name: "广告主管理",
            path: "/pages/ad-vertisers",
            component: () => import("@/views/AdVertisers/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "费率配置",
            path: "/pages/ad-rates",
            component: () => import("@/views/AdRates/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "上下游匹配",
            path: "/pages/traffic-auto-filter",
            component: () => import("@/views/TrafficAutoFilter/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          }
        ]
      },
      {
        name: "媒体端管理",
        path: "/pages/media",
        children: [
          {
            name: "渠道管理",
            path: "/pages/media-channel",
            component: () => import("@/views/MediaChannel/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "CRID策略",
            path: "/pages/crid-strategy",
            component: () => import("@/views/CridStrategy/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "CRID查询",
            path: "/pages/crid-report",
            component: () => import("@/views/ReportCrid/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          }
        ]
      },
      {
        name: "报表管理",
        path: "/pages/report",
        children: [
          {
            name: "实时流量",
            path: "/pages/report-real",
            component: () => import("@/views/RealTime/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "花费报表",
            path: "/pages/report-spend",
            component: () => import("@/views/Spend/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "综合报表",
            path: "/pages/report-overrall",
            component: () => import("@/views/OverrallReport/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "DSP-API报表",
            path: "/pages/report-dsp",
            component: () => import("@/views/DspReport/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "SChain报表",
            path: "/pages/report-schain",
            component: () => import("@/views/Schain/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "AVG报表",
            path: "/pages/report-avg",
            component: () => import("@/views/Avg/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "IT成本&OKR",
            path: "/pages/report-IT&OKR",
            component: () => import("@/views/IT&OKR/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "线索报表",
            path: "/pages/report-clue",
            component: () => import("@/views/Clue/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "数据交付报表",
            path: "/pages/tecad-report",
            component: () => import("@/views/ReportTecad/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "ECPR报表",
            path: "/pages/report-ecpr",
            component: () => import("@/views/ReportEcpr/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          }
        ]
      },
      {
        name: "DSP管理",
        path: "/pages/dsp",
        children: [
          {
            name: "创意分析",
            path: "/pages/dsp-creativity",
            component: () => import("@/views/DspCreativity/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          }
        ]
      },
      {
        name: "财务管理",
        path: "/pages/finance",
        children: [
          {
            name: "冻结数据",
            path: "/pages/finance-freeze",
            component: () => import("@/views/FinanceFreeze/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          }
        ]
      },
      {
        name: "SDK统计",
        path: "/pages/sdk",
        children: [
          {
            name: "日志明细分析",
            path: "/pages/sdk-log",
            component: () => import("@/views/SdkLog/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          },
          {
            name: "DAU",
            path: "/pages/sdk-dau",
            component: () => import("@/views/SdkDau/index.vue").catch(onError),
            meta: {
              keepAlive: true
            },
          }
        ]
      },
      {
        name: "系统管理",
        path: "/pages/system",
        children: [
          {
            name: "用户管理",
            path: "/pages/system-user",
            component: () => import("@/views/SystemUser/index.vue").catch(onError),
            meta: {
              keepAlive: true
            }
          }
        ]
      }
    ]
  }
];
// console.log(routes, '====')
export default routes.concat(constantRoutes);
